import React, { useState } from 'react';
import axios from 'axios';

import jsreport from '@jsreport/browser-client'

const FileUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [response, setResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFile(files[0]);
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      alert('Por favor, selecione um arquivo primeiro!');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    setLoading(true);
    setResponse(null);

    try {
      const res = await axios.post('https://rd-api.fpcvyb.easypanel.host/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      (jsreport as any).serverUrl = 'https://rd-jsreport.fpcvyb.easypanel.host';
        const report =  jsreport.render({
           "template": { "name": "budget" },
           "data":res.data,
           "options": { "reports": { "save": false } }

        }).then(function(res){
           res.openInWindow()
           setLoading(false);
        }) .catch(function(err){
          setLoading(false);

        })
      setResponse(res.data);
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
    }/* finally {
      setLoading(false);
    } */
  };

  return (
    <div className="flex flex-col items-center justify-center border-2 border-dashed border-gray-400 p-4 rounded-md">
      <input type="file" onChange={handleFileChange} className="mb-4" />
      <button onClick={handleSubmit}
         disabled={loading}
          className="bg-blue-600 w-full text-white px-4 py-2 rounded">
                {loading ? 'Carregando...' : 'Gerar'}
      </button>
    </div>
  );
};

export default FileUpload;