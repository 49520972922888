import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="p-4 text-black flex justify-between items-center border-b">
      <img src="/logo.png" alt="Logo" className="w-auto h-12 " />
      <h1 className="text-black text-md font-medium mr-4">Gerador de Proposta v1</h1>
    </header>
  );
};

export default Header;