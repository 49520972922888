import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="p-4 text-black flex justify-center items-center border-t">
      <h1 className="text-black text-[10px] font-medium mr-4">Copyright © 2024. Desenvolvido por Crysthyano Forteski Domingos - Todos os direitos reservados.</h1>
    </footer>
  );
};

export default Footer;